import React from "react"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import CUSTOM_CMS_INPUTS from "../custom_cms_inputs"
import { CMSProps } from "@rubendenbrok/bloqmatter-cms/CMS"

export default function cms() {
  const LoadableProductionCMS = Loadable<CMSProps>(
    () => import("@rubendenbrok/bloqmatter-cms/web/CMS")
  )
  const LoadableDevCMS = Loadable<CMSProps>(
    () => import("@rubendenbrok/bloqmatter-cms/CMS")
  )

  if (!process.env.GATSBY_SITEID) {
    throw new Error("please add GATSBY_SITEID to environment variables")
  }
  if (process.env.NODE_ENV === "development") {
    return (
      <LoadableDevCMS
        layoutComponent={Layout}
        customInputs={CUSTOM_CMS_INPUTS}
        siteId={process.env.GATSBY_SITEID}
      />
    )
  }

  return (
    <>
      {typeof window !== "undefined" && (
        <LoadableProductionCMS
          layoutComponent={Layout}
          customInputs={CUSTOM_CMS_INPUTS}
          siteId={process.env.GATSBY_SITEID}
        />
      )}
    </>
  )
}
